import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { PaymentIntent, Rental } from '~/types/api'

interface ConfirmationPageData {
    rental: Rental
    paymentIntent: PaymentIntent | undefined | null
}

export function getRentalDataKey(route: RouteLocationNormalizedLoaded) {
    return 'rental-fetched-key-' + route.params.id
}

export function useAsyncRentalData(getPaymentIntent = true) {
    const route = useRoute()

    const key = getRentalDataKey(route)
    const { $apiFetch } = useNuxtApp()

    return useAsyncData<ConfirmationPageData>(key, async () => {
        const rental = await $apiFetch<Rental>('/rentals/' + route.params.id)
        let paymentIntent = null
        usePaymentIntentCreatedState().value = false
        /*
         * If rental is not paid or cancelled, check if there is a pending payment intent
         */
        if (getPaymentIntent && rental.status !== 'paid' && rental.status !== 'cancelled') {
            const currentUser = useCurrentUserState()

            if (rental && rental.paymentIntents) {
                const pendingPaymentIntentId = rental.paymentIntents.find(
                    (pi: PaymentIntent) => pi.status === 'transaction_pending',
                )?.id
                if (pendingPaymentIntentId) {
                    /*
                     * Retrieve waiting payment intent
                     */
                    paymentIntent = await $apiFetch<PaymentIntent>('/payment_intents/' + pendingPaymentIntentId)
                }
            }
            if (!paymentIntent) {
                /*
                 * Create a payment intent
                 */
                paymentIntent = await $apiFetch<PaymentIntent>('/payment_intents', {
                    method: 'POST',
                    body: JSON.stringify({
                        user: currentUser.value?.['@id'],
                        rental: rental['@id'],
                    }),
                })
                // Need to share a boolean state if async-data is called from server side
                usePaymentIntentCreatedState().value = true
            }
        }

        return {
            rental,
            paymentIntent,
        }
    })
}
